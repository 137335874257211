<template>
  <div class="uk-section aboutUs-floor2">
    <div class="uk-container uk-container-large aboutUs-floor2">
      <div class="aboutUs-floor2-title">公司概况</div>
      <div class="aboutUs-floor2-subtitle">
        吾叙酒旅通是国内专业提供文旅SaaS平台服务的产业互联网科技公司。
      </div>
      <div class="aboutUs-floor2-content">
        公司成立于2016年7月，曾服务过国内知名旅游酒店集团和saas软件公司。例如订单来了、行李旅宿。公司坐落于成都高新技术开发区天府新谷。目前主营业务为旅行社、景区、营地、酒店、度假民宿等提供saas软件和运营服务。主要专注于文旅垂直行业，通过saas+解决方案为文旅企业快速解决在各种场景下的营销需求。多渠道营销链接、达人分销带货、裂变营销策划、直播代运营等「运营全链路解决方案」。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less">
.aboutUs-floor2 {
  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #333333;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 60px;
    &::after {
      position: absolute;
      position: absolute;
      display: block;
      content: "";
      width: 5%;
      height: 7px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: @linear-gradient;
    }
  }
  &-subtitle {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 40px;
  }
  &-content {
    font-size: 26px;
    color: #555;
    line-height: 2;
  }
}
</style>