<template>
  <div class="aboutUs-floor5">
    <div class="uk-section">
      <div class="uk-container uk-container-large">
        <div uk-grid>
          <div class="uk-width-1-2">
            <div class="aboutUs-floor5-title">立即致电专属顾问</div>
            <div class="aboutUs-floor5-subtitle">
              拨打资讯电话，任何问题，1对1为您解答
            </div>
            <div class="aboutUs-floor5-lx uk-margin-medium">
              <div class="uk-margin-medium">
                <img
                  class="aboutUs-floor5-lx-img"
                  src="@/assets/24658c5f-2e00-44d8-b788-a6e87ab94e87.png"
                />
              </div>
              <div class="aboutUs-floor5-lx-dh">18081949861</div>
              <div class="aboutUs-floor5-lx-gzsj">工作时间9:00-21:00</div>
            </div>
          </div>
          <div class="uk-width-1-2">
            <div class="aboutUs-floor5-title">留下您的联系方式</div>
            <div class="aboutUs-floor5-subtitle">专属顾问会来电与您沟通</div>
            <div class="aboutUs-floor5-form">
              <form>
                <div class="uk-margin-medium">
                  <input
                    class="uk-input uk-form-large"
                    type="text"
                    placeholder="姓名"
                  />
                </div>
                <div class="uk-margin-medium">
                  <input
                    class="uk-input uk-form-large"
                    type="text"
                    placeholder="手机号"
                  />
                </div>
                <div class="uk-margin-medium">
                  <button
                    type="button"
                    @click="showQr"
                    class="uk-button uk-button-danger uk-width-1-1 uk-form-large"
                  >
                    预约专属顾问
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.aboutUs-floor5 {
  background-image: @linear-gradient;
  &-title {
    font-size: 42px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
  }
  &-subtitle {
    font-size: 26px;
    color: #fff;
    text-align: center;
  }
  .uk-input {
    border-radius: 10px;
  }
  .uk-button-danger {
    border-radius: 10px;
    background: #ff8956;
  }
  &-form {
    width: 60%;
    margin: auto;
    margin-top: 50px;
  }
  &-lx {
    text-align: center;
    color: #fff;
    &-img {
      width: 136px;
      height: 136px;
      border-radius: 68px;
      display: block;
      margin: auto;
    }
    &-dh {
      font-size: 42px;
      font-weight: bold;
    }
    &-gzsj {
      font-size: 26px;
    }
  }
}
</style>