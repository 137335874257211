<template>
  <home-floor-8 />
</template>

<script>
import homeFloor8 from "@/views/index/components/floor8.vue";
export default {
  components: {
    homeFloor8,
  },
};
</script>

<style lang="less">
</style>