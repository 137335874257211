<template>
  <div class="aboutUs-floor1">
    <div class="aboutUs-floor1-bg">
      <div class="uk-section">
        <div class="uk-container uk-container-large">
          <div class="aboutUs-floor1-title">
            我们不喜欢平静，那样会让我们迟钝
          </div>
          <div class="aboutUs-floor1-subtitle">每个梦想我们都会鼎力相助</div>
          <div class="aboutUs-floor1-content">
            <div uk-grid class="uk-child-width-1-4 uk-grid">
              <div v-for="(item, index) in list" :key="index">
                <div class="aboutUs-floor1-item">
                  <div class="aboutUs-floor1-item-value">
                    <numscrollspy :vlaue="item.value" />
                    <span>{{ item.unti }}</span>
                  </div>
                  <div class="aboutUs-floor1-item-label">{{ item.label }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numscrollspy from "@/components/numscrollspy.vue";
export default {
  components: {
    numscrollspy,
  },
  data() {
    return {
      list: [
        {
          value: 1000,
          unti: "+",
          label: "累计服务客户",
        },
        {
          value: 500,
          unti: "+",
          label: "文旅企业合作品牌",
        },
        {
          value: 1000,
          unti: "+",
          label: "新媒体带货达人",
        },
        {
          value: 99.85,
          unti: "%",
          label: "技术稳定性",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.aboutUs-floor1 {
  background-image: @linear-gradient;

  &-bg {
    background-image: url("~@/assets/bfb8adff-bda6-4ee8-b636-ccc52e267ba6.png");
    background-size: cover;
    padding: 170px 0;
  }

  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
  }

  &-subtitle {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 72px;
  }

  &-content {
    margin-top: 140px;
  }

  &-item {
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    height: 197px;
    border: 1px solid #f9b8b1;
    border-radius: 10px;
    padding: 50px 15px;
    color: #fff;
    line-height: 1;
    text-transform: uppercase;
    &-label {
      font-size: 27px;
    }

    &-value {
      font-size: 58px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
}
</style>