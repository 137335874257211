<template>
  <div class="uk-section aboutUs-floor3">
    <div class="uk-container uk-container-large aboutUs-floor3">
      <div class="aboutUs-floor3-title">为什么选择我们？</div>
      <div class="aboutUs-floor3-subtitle">我们更懂旅游 更懂客户需求</div>
      <div uk-grid class="uk-child-width-1-3">
        <div v-for="(item, index) in list" :key="index">
          <div class="aboutUs-floor3-item">
            <div class="aboutUs-floor3-item-title">{{ item.label }}</div>
            <div class="aboutUs-floor3-item-desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          label: "技术",
          desc: "7年技术积累，我们拥有专业、成熟的开发技术，专注微信生态下打造的数百种营销能力，精细打磨产品，持续迭代更新，为旅游企业赋能。",
        },
        {
          label: "产品",
          desc: "专注为客户做一款好产品，为品牌提供从获客到成交转化的全方位解决方案。基于微信生态下的社交电商拉新获客、成交转化、顾客留存、分享裂变、运营迭代，深度经营。",
        },
        {
          label: "服务",
          desc: "吾叙酒旅通设有客户成功中心，有专属在线答疑支持。行业伙伴交流，配套资料、教程文档、行业优秀案例，免费参与不定期的线上课程和线下沙龙。",
        },
        {
          label: "团队",
          desc: "专业的产研团队，80%拥有10年以上互联网开发经验，专业的产品经理，需求分析师，交互体验师，使产品迭代进度更快，质量更高。",
        },
        {
          label: "保障",
          desc: "帮助旅游从业者实现营收增长，为他们提供平台重要保障，360°全方位赋能，建立共赢发展模式，构建门店顾客经营数字化体系。",
        },
        {
          label: "体验",
          desc: "专注创造优秀的用户体验，热衷于像素级别的视觉感官，领先视觉当下流行审美专业开发。",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.aboutUs-floor3 {
  &-title {
    font-size: 40px;
    line-height: 58px;
    color: #333333;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    margin-bottom: 60px;
    &::after {
      position: absolute;
      position: absolute;
      display: block;
      content: "";
      width: 5%;
      height: 7px;
      border-radius: 3.5px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: @linear-gradient;
    }
  }
  &-subtitle {
    text-align: center;
    font-size: 32px;
    line-height: 47px;
    color: #666666;
    margin-bottom: 80px;
  }

  &-item {
    text-align: center;
    &-title {
      font-size: 34px;
      line-height: 44px;
      color: #333333;
      margin-bottom: 20px;
    }
    &-desc {
      font-size: 18px;
      line-height: 32px;
      color: #000000;
    }
  }
}
</style>