<template>
  <div>
    <floor-1 />
    <floor-2 />
    <floor-3 />
    <floor-4 />
    <floor-5 />
  </div>
</template>

<script>
import floor1 from "./components/floor1.vue";
import floor2 from "./components/floor2.vue";
import floor3 from "./components/floor3.vue";
import floor4 from "./components/floor4.vue";
import floor5 from "./components/floor5.vue";
export default {
  components: {
    floor1,
    floor2,
    floor3,
    floor4,
    floor5
  },
};
</script>

<style>
</style>